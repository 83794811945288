<template>
  <div class=""></div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      row: {
        //
      },
    };
  },
  mounted() {},
};
</script>
